@font-face {
  font-family: 'Fira Sans Book';
  src: url('../fonts/firasans-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Fira Sans Book', sans-serif;
  line-height: 1.5rem;
  font-size: 1.1rem;
  background: lightslategray;
}

#container {
  background: white;
}

hr {
  border: 0;
  border-top: 2px dashed black;
  margin: 1rem 0.5rem;
}

a:hover {
  text-decoration: none;
}

header {
  text-align: center;

  .ಠ_ಠ { display: none; }

  h1 {
    font-size: 2rem;
    margin-top: 0;
    padding: 1rem 1rem 0 1rem;

    a { color: #333; }
  }
}

article {
  h2 {
    background: #222;
    color: #fff;
    line-height: 1.2;
    margin-bottom: 0;
    padding: 1rem 0.5rem;

    a { color: #fff; }
  }

  h3, h4 { padding: 0 1rem; }

  time { font-family: monospace; cursor: help; }

  // Prism syntax highlighting tries to overwrite this with a more specific rule
  pre { margin: auto 1rem !important; }

  p {
    margin: 1rem 0.5rem;
    text-align: justify;
  }

  code { background: #eee; }

  figure {
    text-align: center;

    img, video {
      border: 2px solid #333;
      box-sizing: border-box;
      width: 95%;
    }

    figcaption { display: block; font-size: 0.8rem; }
  }
}

section.comments {
  margin: 1rem;

  .comment {
    background: #eee;
    font-size: 1rem;
    margin: 1rem;
    padding: 0.5rem;
  }

  #comment_form {
    label {
      display: inline-block;
      font-weight: bold;
      padding-right: 1rem;
      text-align: right;
      vertical-align: top;
      width: 6rem;
    }

    .h-captcha { margin-left: 8rem; }

    input,
    textarea {
      border: 2px solid #ccc;
      padding: 0.5rem;
      width: 60%;
    }

    button {
      border: 2px solid #ccc;
      cursor: pointer;
      font-size: 1.25rem;
      margin-left: 8rem;
      padding: 0.5rem;
    }
  }

  #comment_error_msg {
    background: lightpink;
    border: 2px solid crimson;
    font-weight: bold;
    padding: 0.5rem 1rem;
  }
}

.pagination {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-content: center;

  a, span {
    display: inline-block;
    padding: 1rem;
  }

  a {
    border: 1px solid black;
  }

  span.previous, span.next {
    background: #eee;
    color: #666;
  }
}

footer {
  margin-bottom: 1rem;
  padding: 1px;

  .widget {
    padding: 0 1rem 1rem 1rem;
    border: 0;
    border-bottom: 2px dashed black;
    margin: 1rem 0.5rem;

    .phone {
      background: #333;
      border-radius: 1rem;
      margin: 0 25%;
      padding: 1rem 0.25rem 0.5rem 0.25rem;
      text-align: center;
      width: 50%;

      img {
        width: 90%;
        border-radius: 0.5rem;
      }
    }
  }

  .about, .copyright { text-align: center; }
}

// "Desktop"
@media (min-width: 768px) {
  #container {
    margin: auto;
    width: 75%;
  }

  header {
    text-align: left;

    a { float: right; }

    .ಠ_ಠ { display: inline; }
  }

  article {
    h2 { padding: 1rem; }

    p { margin: 1rem; }

    .figure-group {
      display: flex;
      justify-content: space-evenly;
      align-items: baseline;

      figure {
        img { width: 100% }
      }
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .widget {
      width: 20%;
    }

    .copyright {
      width: 100%;
    }
  }
}
